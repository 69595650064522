<template>
  <base-info editPath="userEdit" :baseInfoList="baseInfoList" :detailInfo="userInfo"></base-info>
</template>

<script>
import BaseInfo from '../BaseInfo'
import {getUserInfo} from '@/api';
export default {
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  data(){
    return {
      userInfo:{},
      baseInfoList:[
        {
          title:"项目部名称",
          field:"name"
        },
        {
          title:"所属总包部名称 ",
          field:"company_name"
        },
        {
          title:"管理员姓名",
          field:"people_name"
        },
        {
          title:"联系电话",
          field:"phone_number"
        },
        {
          title:"设置监督岗总数",
          field:"sentry_num"
        },
        {
          title:"",
          field:""
        },
      ],
    }
  },

  components:{
    BaseInfo
  }
}
</script>

<style scoped>

</style>
