<template>
  <right-content>
    <div class="editBtn">
      <el-button type="mini" @click="editInfo">修改信息</el-button>
    </div>
    <div class="baseInfo">
      <div class="info-image" v-if="userInfo">
        <img :src="userInfo.img" alt="图片" />
      </div>
      <div class="info-content">
        <el-descriptions :column="2" border>
          <el-descriptions-item
            :key="index"
            v-for="(item, index) in baseInfoList"
          >
            <template slot="label">
              {{ item.title }}
            </template>
            {{ userInfo[item.field] }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </right-content>
</template>

<script>
import RightContent from "../commonViews/RightContent.vue";

export default {
  components: { RightContent },
  methods: {
    editInfo() {
      this.$router.push({
        path: this.editPath,
        query: {
          info: JSON.stringify(this.userInfo),
        },
      });
    },
  },
  data() {
    return {
      userInfo: "",
    };
  },
  watch: {
    detailInfo: {
      handler(val) {
        this.userInfo = val;
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    editPath: {
      type: String,
    },
    detailInfo: {
      type: Object,
    },
    baseInfoList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  margin: 10px;
  background-color: red;
  color: #fff;
  padding: 8px 25px;
  border-radius: 5px;
}

.baseInfo {
  margin: 100px 200px;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .info-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-content {
    margin-top: 30px;
    width: 100%;
  }
}
::v-deep .el-descriptions-item__label {
 width: 180px;
  padding: 0 !important;
  background-color: darkgray;
  font-weight: 100;
  color: black;
  &::before{
    content: "";
    margin-left: 20px;
  }
}

::v-deep .el-descriptions-item__content{
  width: 300px;
}

</style>
